import _ from 'lodash';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState, useCallback } from 'react';

import { HOME_URL } from 'libs/constants';
import PusherManager from 'libs/pusher';
import { setIsFetchingInitials } from 'libs/reducer';
import { login } from 'libs/requests';

import { Page, Container, LoginInProgress } from 'components';

import { AppContext } from './_app';

const Login = () => {
  const [logged, setLogged] = useState(false);
  const [loginHasFailed, setLoginHasFailed] = useState(false);
  const router = useRouter();
  const { dispatch } = useContext(AppContext);
  const authToken = router.query.token;

  const fetchUser = useCallback(
    async ({ authToken }: { authToken: string | string[] }) => {
      const { success } = await login({ authToken });

      setLogged(success);
      setLoginHasFailed(!success);

      if (dispatch && success) {
        PusherManager.updateAuthenticationHeader();
        dispatch(setIsFetchingInitials(true)); // so that we unblock the `initials` fetching
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (authToken) {
      fetchUser({ authToken });
    }
  }, [authToken, fetchUser, router]);

  useEffect(() => {
    if (logged) {
      const nextPage = _.get(router, 'query.redirectPath', HOME_URL);
      router.push(nextPage);
    }
  }, [logged, router]);

  return (
    <Page withQuickGuideModal={false}>
      <Container.Large>
        <LoginInProgress failed={loginHasFailed} />
      </Container.Large>
    </Page>
  );
};

export default Login;
